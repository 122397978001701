<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'pt-2' : ''">
    <v-menu
      v-model="menuResults"
      offset-y
      rounded="xl"
      max-width="800"
    >
      <!-- nudge-bottom="-10" -->
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="search"
          id="cajabuscar"
          clearable
          outlined
          dense
          class="rounded-xl elevation-0"
          hide-details
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
        <!-- style="background-color: #ffffff !important; box-shadow: none !important; border:1px solid rgba(0, 0, 0, 0.2) !important" -->
      </template>
      <v-card  flat v-if="(items && items.length) || (itemsSuggest && itemsSuggest.length)" max-width="800">
        <div v-if="itemsSuggest && itemsSuggest.length" class="text-start pl-6 pt-3">
          <p class="mb-0 caption font-weight-bold text--secondary">Texto sugerido:</p>
          <a
            v-for="(itemSgt, i) in itemsSuggest"
            :key="i"
            class="text-start caption"
            v-html="itemSgt.text"
            @click="search = itemSgt.id"
          >
          </a>
        </div>
        <v-list v-if="items && items.length">
          <v-list-item
            v-for="(item) in items"
            :key="item.id"
            @click="goToResult(item.id)"
            :three-line="!!item.properties"
          >
            <!-- <v-list-item-avatar tile size="50" class="rounded">
              <v-img
                :src="item.picture ? item.picture : '/no_image.png'"
              >
              </v-img>
            </v-list-item-avatar> -->
            <v-list-item-content class="my-0 py-0 pl-2">
              <v-list-item-title class="caption text-start"><span v-html="item.name"></span></v-list-item-title>
              <v-list-item-subtitle class="mb-0 pb-0">
                <div v-if="item.categories" class="text-start">
                  <span class="small-text py-1 primary--text">Categorías:</span>
                  <span
                    v-for="(category, i) in item.categories"
                    :key="i"
                    class="small-text py-1 px-2 grey lighten-3 rounded mx-1 text--primary"
                    v-html="category.value"
                  >
                  </span>
                </div>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="mb-0 pb-0">
                <div v-if="item.properties" class="text-start">
                  <span class="small-text py-1 primary--text">Propiedades:</span>
                  <span
                    v-for="(property, i) in item.properties"
                    :key="i"
                    class="small-text py-1 px-2 grey lighten-3 rounded mx-1 text--primary"
                    v-html="property.value"
                  >
                  </span>
                </div>
              </v-list-item-subtitle>
              <v-divider class="mr-2"></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
const algoliasearch = require('algoliasearch')
const aplicationId = 'URAAT29DAP'
const searhApiKey = 'e00890ff99b3e1a5c4bff20d7936b5e1'
const algoliaIndex = 'donations_post'
const algoliaIndexSuggest = 'donations_post_query_suggestions'
const clientAlgolia = algoliasearch(aplicationId, searhApiKey)
const clientAlgoliaSugguest = algoliasearch(aplicationId, searhApiKey)
// Create a new index and add a record
const index = clientAlgolia.initIndex(algoliaIndex)
const indexSuggest = clientAlgoliaSugguest.initIndex(algoliaIndexSuggest)
export default {
  name: 'SearchBox',
  data: ()  => ({
    items: [],
    itemsSuggest: [],
    search: null,
    menuResults: true
  }),
  watch: {
    search (newVal) {
      if (newVal) {
        this.performSearch(newVal)
        // this.performSearchSuggest(newVal)
      } else {
        this.setResults(null)
        this.setResultsSuggest(null)
      }
    },
    items (newVal) {
      if (newVal && newVal.length) {
        this.menuResults = true
      }
    }
  },
  methods: {
    goToResult(id) {
      const ruta = {
        name: 'detail',
        params: {
          id: id
        }
      }
      this.$router.replace(ruta)
      this.search = null
    },
    async   performSearchSuggest (value) {
      const { hits } = await indexSuggest.search(value, {
        hitsPerPage: 5
      })
      const results = hits.map(hit => {
        const { objectID, _highlightResult } = hit
        return { objectID, _highlightResult }
      })
      this.setResultsSuggest(results)
    },
    setResultsSuggest (results) {
      this.itemsSuggest = []
      if (results && results.length) {
        results.forEach(item => {
          this.itemsSuggest.push({
            id: item.objectID,
            text: item._highlightResult.query.value
          })
        });
      } else {
        this.itemsSuggest = []
      }
    },
    async   performSearch (value) {
      const { hits } = await index.search(value, {
        hitsPerPage: 5,
        filters: 'publish: true AND available >= 1'
        
      })
      const results = hits.map(hit => {
        const { objectID, picture, _highlightResult } = hit
        return { objectID, picture,  _highlightResult }
      })
      this.setResults(results)
    },
    setResults (results) {
      this.items = []
      if (results && results.length) {
        results.forEach(item => {
          this.items.push({
            id: item.objectID,
            name: item._highlightResult.name.value,
            picture: item.picture,
            categories: item._highlightResult.categories,
            properties: item._highlightResult.properties
          })
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>
<style lang="scss">
em {
  background-color: rgba($color: #00FFAB, $alpha: 0.4);
  font-style: normal;
  font-weight: 600;
}
</style>
