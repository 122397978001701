<template>
  <v-footer
    absolute
    app
    dark
    class="white--text"
  >
    <v-container fluid>
      <v-row dense class="justify-center">
        <v-col
          v-for="(info, i) in info"
          :key="i"
          cols="auto"
        >
          <a
            class="caption f-resaltarlink white--text font-weight-bold mx-2"
            :href="info.link"
          >
            {{ info.nombre }}
          </a>
        </v-col>
      </v-row>
      <v-row dense class="justify-center">
        <v-col
          class="subtitle-1 text-center"
          cols="auto"
        >
          <router-link class="ml-1 caption donategreen--text" style="text-decoration:none" to="/">
            Copyright &copy; {{ (new Date()).getFullYear() }} •
            <v-avatar
              tile
              size="18"
              style="cursor: pointer;"
            >
              <v-img
                src="/logo.png"
                contain
              >
              </v-img>
            </v-avatar>
            Donarlo.org
          </router-link>
          <!-- <span class="ml-3">-</span> -->
          <!-- <v-btn
            text
            target="_blank"
            href="https://api.whatsapp.com/send?phone=593987679471&text=Saludos,%20necesito%20informaci%C3%B3n%20de%20*Donarlo.org*.%20Mi%20nombre%20es%20"
            style="text-transform:none"
          >
            <v-icon x-small left>fa-brands fa-whatsapp</v-icon>
            0987-679-471
          </v-btn> -->
          <!-- <v-btn
            text
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5930984674974&text=Saludos,%20necesito%20informaci%C3%B3n%20de%20*TAKED*.%20Mi%20nombre%20es%20"
            style="text-transform:none"
          >
            <v-icon x-small left>fa-brands fa-whatsapp</v-icon>
            0984-674-974
          </v-btn> -->
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterSite',
  data: () => ({
    info: [
      {
        nombre: 'Quiero colaborar',
        link: '/colaborate'
      },
      {
        nombre: 'Políticas y procedimientos',
        link: '/polices'
      },
      {
        nombre: 'Contactarme',
        link: '/contact'
      }
    ]
  })
}
</script>

<style lang="scss">
.f-resaltarlink {
  text-decoration: none;
  display: inline-block;
  position: relative;
}
.f-resaltarlink:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #00FFAB;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition-delay: 50ms;
  width: 0;
}
.f-resaltarlink:hover:after {
  transition-delay: 0.2s;
  width: 100%;
  left: 0%;
}
</style>
