<template>
<div class="pb-1 linebottom">
  <v-app-bar
    flat
    color="white"
    :height="$vuetify.breakpoint.lgAndUp ? '80' : '140'"
    :max-height="$vuetify.breakpoint.lgAndUp ? '80' : '140'"
    style="z-index: 9"
  >
    <v-row class="justify-space-around aling-center">
      <v-col cols="6" sm="4" md="3" lg="2">
        <router-link class="d-flex align-center" to="/">
          <v-img
            alt="Bussines logo"
            contain
            src="/logoBlack.png"
            transition="scale-transition"
            height="55"
            width="100"
          />
        </router-link>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="11" md="7" lg="4">
        <searh-box></searh-box>
      </v-col>
      <v-col
        cols="6"
        md="3"
        class="d-flex justify-end align-center"
      >
        <notification-btn></notification-btn>
        <v-menu
          offset-y
          rounded="xl"
          transition="slide-y-reverse-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              outlined
              class="rounded-xl py-1 px-2"
              v-bind="attrs"
              v-on="on"
              width="85"
            >
              <v-icon left small color="grey darken-4">
                fa-bars
              </v-icon>
              <v-avatar class="ml-2" size="32">
                <v-img
                  eager
                  referrerpolicy="no-referrer"
                  v-bind:src="photoURL"
                  lazy-src="/user_ninja.png"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 align-center justify-center"
                    >
                      <v-progress-circular
                        indeterminate
                        size="20"
                        width="3"
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </v-sheet>
          </template>
          <v-card flat class="rounded-xl" min-width="300">
            <v-row dense class="grey lighten-4 ma-0 pt-2">
              <v-col cols="12">
                <p class="subtitle-1 mb-0 font-weight-bold toguether-text">{{ userName }}</p>
                <p class="caption my-0 font-weight-light text--secondary">{{ userMail }}</p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-text class="text--primary pt-0">
              <v-row dense>
                <v-col cols="12">
                  <v-list>
                    <v-list-item
                      v-for="item in menu"
                      :key="item.id"
                      :to="item.link"
                    >
                      <v-list-item-avatar class="text-start" size="18">
                        <v-icon small>{{ item.icon }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="text-start">{{ item.text }}</v-list-item-title>
                    </v-list-item>
                    <!-- <v-divider></v-divider>
                    <v-list-item
                      @click="logOut"
                    >
                      <v-list-item-avatar tile class="text-start" size="18">
                        <v-icon small>fa-arrow-right-from-bracket</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="text-start">Cerrar sesión</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pa-0">
              <v-btn
                block
                text
                tile
                class="rounded-b"
                @click="logOut"
              >
                <v-icon small left>fa-arrow-right-from-bracket</v-icon>
                Cerrar sesión
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" sm="11" md="10">
        <searh-box></searh-box>
      </v-col>
    </v-row>
  </v-app-bar>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { storage } from '@/plugins/firebase'
import { getAuth, signOut } from 'firebase/auth'
import { ref, getDownloadURL } from "firebase/storage"
import NotificationBtn from '../notifications/NotificationBtn.vue'
import SearhBox from './SearhBox.vue'
export default {
  name: 'AppBar',
  components: {
    NotificationBtn,
    SearhBox
  },
  data: () => ({
    loading: false,
    notificaciones: [],
    overTimes: 0,
    myTimeout: null,
    photoURL: null,
    menu: [
      {
        id: 0,
        text: 'Mis donaciones',
        icon: 'fa-hand-holding-hand',
        link: '/review'
      },
      {
        id: 1,
        text: 'Mis postulaciones',
        icon: 'fa-hand',
        link: '/ineed'
      },
      {
        id: 2,
        text: 'Me gusta',
        icon: 'fa-heart',
        link: '/liked'
      },
      {
        id: 3,
        text: 'Perfil',
        icon: 'fa-user',
        link: '/profile'
      }
    ]
  }),
  computed: {
    ...mapGetters(['logeado', 'userPhoto', 'userName', 'userMail']),
    isHome () {
      return this.$route.name === 'home'
    },
    isApp () {
      return this.$route.name === 'app'
    }
  },
  watch : {
    userPhoto() {
      this.overTimes = 0
      this.myTimeout = setInterval(() => {
        this.getImg('120')
      }, 3000)
    }
  },
  created () {
    if (this.userPhoto) {
      this.getImg('120')
    }
  },
  methods: {
    ...mapActions(['logout']),
    async getImg(size = null) {
      const photo = this.userPhoto
      const store = 'users'
      if (photo && photo.length) {
        if (photo.startsWith('http')) {
          this.photoURL = photo
          clearInterval(this.myTimeout)
        } else {
          let refImg = null
          if (size) {
            const thumb = 'thumb_'+ size +'_'
            refImg = ref(storage, store +'/' + thumb + photo)
          } else {
            
            refImg = ref(storage, store +'/' + photo)
          }
          await getDownloadURL(refImg)
          .then((imageURL) => {
            this.overTimes = 0
            clearInterval(this.myTimeout)
            this.photoURL = imageURL
          }).catch((err) => {
            if (err.code === 'storage/object-not-found' && this.overTimes < 5) {
              this.overTimes++
              this.getImg('120')
            } else if (err.code === 'storage/object-not-found' && this.overTimes === 5) {
              this.overTimes++
              this.getImg()
            } else {
              this.overTimes = 0
              clearInterval(this.myTimeout)
              this.photoURL = '/no_image.png'
            }
          })
        }
      } else {
        this.photoURL = '/no_image.png'
      }
    },
    logOut () {
      const auth = getAuth()
      signOut(auth).then(() => {
        this.logout()
				this.$router.replace('/')
      }).catch(() => {
				this.logout()
				this.$router.replace('/login')
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.link {
  text-decoration: none;
}
.link:hover {
  text-decoration:underline;
}
.linebottom {
  // border-bottom: 10px solid rgba($color: #00aaaa, $alpha: 1) !important;
  border-bottom: 1px solid rgba($color: #000000, $alpha: .05) !important;
}
</style>
