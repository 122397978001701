<template>
  <v-badge
    color="success"
    offset-x="20"
    offset-y="20"
    class="mr-1"
    :content="notifications.length"
    :value="notifications.length"
  >
    <template v-slot:badge>
      <span class="small-text">{{ notifications.length }}</span>
    </template>
    <!-- :disabled="!notifications.length" -->
    <v-btn
      icon
      :color="notifications.length ? 'donateback' : 'grey'"
      to="/notifications"
    >
      <v-icon>
        fa-bell
      </v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex'
import { db } from '@/plugins/firebase'
import { collection, onSnapshot, query, orderBy, where } from "firebase/firestore"
export default {
  name: 'NotificationBtn',
  data: () => ({
    notifications: [],
    suscribeNotifications: null,
  }),
  created () {
    this.getNotifications()
  },
  beforeUnmount () {
    if (this.suscribeNotifications) {
      this.suscribeNotifications()
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  methods: {
    async getNotifications() {
      try {
        const queryData = query(collection(db, "notifications"),
              where("userId","==", this.userId),
              where("state","==", 'Nueva'),
              where("deleted","==", false),
              orderBy("date","desc"))
        this.suscribeNotifications = onSnapshot(queryData, (querySnapshot) => {
          const dataAux = []
          let itemAux = null
          querySnapshot.forEach((doc) => {
            itemAux = doc.data()
            itemAux.id = doc.id
            dataAux.push(itemAux)
            itemAux = null
          })
          this.notifications = dataAux
        })
      } catch (error) {
        this.notifications = []
      }
    }
  }
}
</script>
<style>
</style>
