// import publicAxios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import createMutationsSharer from "vuex-shared-mutations"

Vue.use(Vuex)

export default async function () {

  const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
  const usuario = localStorage.getItem('usuario') ? localStorage.getItem('usuario') : null
  
  const getDefaultState = () => {
    return {
      token: token,
      usuario: usuario
    }
  }

  const store = new Vuex.Store({
    state: getDefaultState,
    mutations: {
      setUsuario (state, usuario) {
        state.usuario = usuario
      },
      setToken (state, token) {
        state.token = token
      },
      RESET_STATE(state) {
        state.token = null
        state.usuario = null
        // state.negocio = null
      },
      updateData (state, dataDetails) {
        state.usuario.nombre = dataDetails.nombre
        state.usuario.photoURL = dataDetails.photoURL
        state.usuario.phoneNumber = dataDetails.phoneNumber
      }
    },
    actions: {
      async login ({ commit }, { authDetails }) {
        const token = authDetails.uid
        const usuario = authDetails
        commit("setUsuario", usuario)
        commit("setToken", token)
        localStorage.setItem('token', token)
        localStorage.setItem('usuario', usuario)
      },
      async updateProfile ({ commit }, { dataDetails }) {
        commit("updateData", dataDetails)
        localStorage.setItem('usuario', (state) => { return state.usuario} )
      },
      logout ({ commit }) {
        commit("RESET_STATE")
        localStorage.clear()
      }
    },
    getters: {
      userPhone: (state) => {
        return state.usuario && state.usuario.phoneNumber ? state.usuario.phoneNumber : null
      },
      userPhoto: (state) => {
        return state.usuario && state.usuario.photoURL ? state.usuario.photoURL : null
      },
      userName: (state) => {
        return state.usuario && state.usuario.nombre ? state.usuario.nombre : null
      },
      userMail: (state) => {
        return state.usuario && state.usuario.email ? state.usuario.email : null
      },
      userId: (state) => {
        return state.usuario && state.usuario.uid ? state.usuario.uid : null
      },
      userRol: (state) => {
        return state.usuario && state.usuario.rol ? state.usuario.rol : null
      },
      logeado: (state) => state.token !== null,
    },
    plugins: [
      createPersistedState({
        storage: localStorage
      },
      createMutationsSharer({
        predicate: (mutation) => {
          // predicate: (mutation, state) => {
          const predicate = [
            "setUsuario",
            "setToken",
            "RESET_STATE",
            "updateData"
          ]
          return predicate.indexOf(mutation.type) >= 0
        }
      })
    )]
  })

  return store
}
