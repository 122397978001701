import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import { getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDOm-psj1E9kssVDG_8rNd85_oXGS4gaOQ",
  authDomain: "telodoy-01.firebaseapp.com",
  projectId: "telodoy-01",
  storageBucket: "telodoy-01.appspot.com",
  messagingSenderId: "651814361238",
  appId: "1:651814361238:web:cc3df2605efc6ba83f0ab7"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig)

// Initialize Auth
const auth = getAuth()
auth.languageCode = 'es';
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebase);

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(firebase);

//Initialize analytics
const analytics = getAnalytics(firebase);

export { firebase, auth, db, storage, analytics }