import Vue from 'vue'
import VueRouter from 'vue-router'
import createStore from '@/store'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/polices',
    name: 'polices',
    component: () => import('../views/PolicesView.vue')
  },
  {
    path: '/colaborate',
    name: 'colaborate',
    component: () => import('../views/ColaborateView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../views/RegisterView.vue')
  // },
  {
    path: '/app',
    name: 'app',
    component: () => import('../views/app/AppView.vue'),
    meta: {
      autenticado: true
    }
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('../views/app/ItemDetailView.vue'),
    meta: {
      autenticado: true
    }
  },
  {
    path: '/liked',
    name: 'liked',
    component: () => import('../views/app/LikesView.vue'),
    meta: {
      autenticado: true
    }
  },
  {
    path: '/ineed',
    name: 'ineed',
    component: () => import('../views/app/CandidatizeView.vue'),
    meta: {
      autenticado: true
    }
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('../views/app/CreateDonation.vue'),
    meta: {
      autenticado: true
    }
  },
  {
    path: '/review',
    name: 'review',
    component: () => import('../views/app/ReviewView.vue'),
    meta: {
      autenticado: true
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/app/NotificationsView.vue'),
    meta: {
      autenticado: true
    }
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('../views/app/UserProfile.vue'),
    meta: {
      autenticado: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/app/ProfileView.vue'),
    meta: {
      autenticado: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  let usuario = null
  await createStore().then(store => {
    usuario = store.getters.logeado
  })
  let autorizacion = to.matched.some(record => record.meta.autenticado)
  if (autorizacion && !usuario) {
    next('/')
  } else if (!autorizacion && usuario) {
    next ('/app')
  } else {
    next()
  }
})

export default router
