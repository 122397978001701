<template>
  <v-app-bar
    flat
    style="z-index: 9"
    color="donategreen"
    :height="$vuetify.breakpoint.smAndDown ? '120' :'60'"
    :max-height="$vuetify.breakpoint.smAndDown ? '120' :'60'"
  >
    <!-- <v-app-bar-nav-icon v-if="logeado && $vuetify.breakpoint.smAndDown" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
    <v-row class="justify-space-around align-center">
      <v-col cols="5" sm="4" md="3" lg="2">
        <router-link class="d-flex align-center" to="/">
          <v-img
            alt="Bussines logo"
            src="/logoBlack.png"
            contain
            transition="scale-transition"
            height="55"
            width="100"
          />
        </router-link>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp"  cols="auto">
        <span class="donateback--text">Con acciones como <strong>donar</strong> puedes llegar a <strong>salvar</strong></span>
      </v-col>
      <v-col v-if="isHome" cols="6" md="2" class="text-right">
        <v-btn
          text
          color="donateback"
          to="/login"
        >
          INGRESAR
          <v-icon right>fa-arrow-right-to-bracket</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndDown"  cols="12" class="text-center">
        <span class="donateback--text">Con acciones como <strong>donar</strong> puedes llegar a <strong>salvar</strong></span>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: 'HomeBar',
  computed: {
    isHome () {
      return this.$route.name === 'home'
    },
    isApp () {
      return this.$route.name === 'app'
    }
  }
}
</script>
<style>
</style>