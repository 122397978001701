<template>
  <v-app>
    <app-bar v-if="logeado"></app-bar>
    <home-bar v-else></home-bar>
    <v-main class="pt-2">
      <router-view />
    </v-main>
    <v-fab-transition>
      <v-btn
        v-show="isFlat"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        class="mb-10"
        color="primary" 
        @click="goTop"
      >
        <v-icon small>
          fa-chevron-up
        </v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
        v-show="!isFlat && logeado && isApp"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        color="donateback"
        class="mb-10"
        @click="toDonate"
      >
        <!-- to="/donate" -->
        <v-icon small>
          fa-plus
        </v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar
      v-model="completarPerfil"
      timeout="4000"
      color="info"
    >
      Para poder Donar es necesario que completes tu <strong>Perfil</strong>
      <template v-slot:action>
        <v-btn
          color="primary"
          depressed
          href="/profile"
        >
          Completar ahora
        </v-btn>
      </template>
    </v-snackbar>
    <app-footer v-if="!logeado"></app-footer>
    <!-- <test-footer v-else></test-footer> -->
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import AppBar from './components/base/AppBar.vue'
import HomeBar from './components/base/HomeBar.vue'
import AppFooter from './components/base/AppFooter.vue'
// import TestFooter from './components/base/TestFooter.vue'
export default {
  name: 'App',
  components: {
    AppBar,
    HomeBar,
    AppFooter
    // TestFooter
  },
  data: () => ({
    isFlat: false,
    completarPerfil: false
  }),
  metaInfo () {
    return {
      title: 'donarlo.org',
      titleTemplate: '%s',
      htmlAttrs: {
        lang: 'es'
      },
      base: { target: '_blank', href: '/' },
      meta: [
        { charset: 'utf-8' },
        { vmid: 'description', name: 'description', content:  'Con acciones como donar puedes llegar a salvar' },
        { name: 'viewport', content: 'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no' },
        { name: 'twitter:card', content: 'sumary' },
        { name: 'twitter:site', content: 'donarlo.org' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'donarlo.org' },
        { name: 'twitter:description', content: 'Con acciones como donar puedes llegar a salvar' },
        { name: 'twitter:image:src', content: 'https://firebasestorage.googleapis.com/v0/b/telodoy-01.appspot.com/o/base%2Fthumb_640_Icon.png?alt=media&token=14b44508-16b7-49a0-97be-e230b2ce87aa' },
        { property: 'fb:app_id', content: '1107988263128174' },
        { property: 'og:site_name', content: 'donarlo.org' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'donarlo.org' },
        { property: 'og:url', content: 'https://donarlo.org' },
        { property: 'og:description', content: 'Con acciones como donar puedes llegar a salvar' },
        { property: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/telodoy-01.appspot.com/o/base%2Fthumb_640_Icon.png?alt=media&token=14b44508-16b7-49a0-97be-e230b2ce87aa' },
        { property: 'og:image:alt', content: 'Con acciones como donar puedes llegar a salvar' },
        { itemprop: 'name', content: 'donarlo.org' },
        { itemprop: 'description', content: 'Con acciones como donar puedes llegar a salvar' },
      ],
      link: [
        { rel: 'canonical', href: 'https://donarlo.org' },
        { rel: 'icon', href: '/logo.png' },
        { rel: 'favicon', href: '/favicon.ico' },
        { rel: 'shortcut icon', type: 'image/png', href: '/logo.png' },
        { rel: 'apple-touch-icon', type: 'image/png', href: '/logo.png' },
        { hid: 'icon', rel: 'icon', type: 'image/x-icon', href: '/logo.png' },
        { hid: 'icon', rel: 'icon', type: 'image/icon', href: '/logo.png' },
        // { hid: 'manifest', rel: 'manifest', href: `data:application/manifest+json,${encodeURIComponent(JSON.stringify(pwa.manifest))}` },
        { rel: 'icon', type: 'image/x-icon', href: '/logo.png' }
      ]
    }
  },
  computed: {
    ...mapGetters(['logeado', 'userPhone']),
    isApp () {
      return this.$route.name === 'app'
    }
  },
  methods: {
    toDonate () {
      if (this.userPhone) {
        this.$router.replace('/donate')
      } else {
        this.completarPerfil = true
      }
    },
    onScroll (e) {
      if (typeof window === 'undefined') { return }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.isFlat = top > 150
    },
    goTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.bg-white{
--tw-bg-opacity:1;
  background-color:rgba(255,255,255,var(--tw-bg-opacity))
}
.bg-gray-100{
  --tw-bg-opacity:1;
  background-color:rgba(243,244,246,var(--tw-bg-opacity))
}
.rounded{
  border-radius:.25rem
}
.cursor-pointer{
  cursor:pointer
}
.flex{
  display:-webkit-box;
  display:-ms-flexbox;
  display:-webkit-flex;
  display:flex
}
.grid{
  display:-ms-grid;
  display:grid
}
.flex-col{
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  -webkit-flex-direction:column;
  flex-direction:column
}
.place-content-center{
  place-content:center
}
.items-end{
  -webkit-box-align:end;
  -ms-flex-align:end;
  -webkit-align-items:flex-end;
  align-items:flex-end
}
.items-center{
  -webkit-box-align:center;
  -ms-flex-align:center;
  -webkit-align-items:center;
  align-items:center
}
.justify-end{
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  -webkit-justify-content:flex-end;
  justify-content:flex-end
}
.justify-center{
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  -webkit-justify-content:center;
  justify-content:center
}
.justify-between{
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  -webkit-justify-content:space-between;
  justify-content:space-between
}
.font-sans{
  font-family:ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji"
}
.font-mono{
  font-family:ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace
}
.font-medium{
  font-weight:500
}
.font-bold{
  font-weight:700
}
.font-semibold{
  font-weight:600
}
.text-xl{
  font-size:1.25rem;
  line-height:1.75rem
}
.text-sm{
  font-size:.875rem;
  line-height:1.25rem
}
.text-2xl{
  font-size:1.5rem;
  line-height:2rem
}
.mb-2{
  margin-bottom:.5rem
}
.mb-4{
  margin-bottom:1rem
}
.mt-4{
  margin-top:1rem
}
.mt-2{
  margin-top:.5rem
}
.max-w-5xl{
  max-width:64rem
}
.min-h-screen{
  min-height:100vh
}
.p-4{
  padding:1rem
}
.p-8{
  padding:2rem
}
.p-1{
  padding:.25rem
}
.pb-1{
  padding-bottom:.25rem
}
.fixed{
  position:fixed
}
.left-0{
  left:0px
}
.right-0{
  right:0px
}
.text-black{
  --tw-text-opacity:1;
  color:rgba(0,0,0,var(--tw-text-opacity))
}
.antialiased{
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}
.w-full{
  width:100%
}
.w-40{
  width:10rem
}
.z-10{
  z-index:10
}
.z-20{
  z-index:20
}
.gap-4{
  grid-gap:1rem;
  gap:1rem
}
.gap-y-4{
  -webkit-row-gap:1rem;
  -moz-row-gap:1rem;
  grid-row-gap:1rem;
  row-gap:1rem
}
.grid-cols-3{
  grid-template-columns:repeat(3,minmax(0,1fr))
}
.col-span-3{
  -ms-grid-column-span:span 3 / span 3;
  grid-column:span 3 / span 3
}
@media (min-width: 640px){
  .sm\:text-base{
    font-size:1rem;
    line-height:1.5rem
  }
  .sm\:pb-2{
    padding-bottom:.5rem
  }
  .sm\:col-span-1{
    -ms-grid-column-span:span 1 / span 1;
    grid-column:span 1 / span 1
  }
}
@media (min-width: 768px){
  .md\:gap-8{
    grid-gap:2rem;
    gap:2rem
  }
}
@media (prefers-color-scheme: dark){
  .dark\:bg-black{
    --tw-bg-opacity:1;
    background-color:rgba(0,0,0,var(--tw-bg-opacity))
  }
  .dark\:bg-white\/10{
    --tw-bg-opacity:.1;
    background-color:rgba(255,255,255,var(--tw-bg-opacity))
  }
  .dark\:text-white{
    --tw-text-opacity:1;
    color:rgba(255,255,255,var(--tw-text-opacity))
  }
}
.spotlight{
  background:linear-gradient(45deg,#00DC82 0%,#36E4DA 50%,#0047E1 100%);
  filter:blur(20vh);
  height:50vh;
  bottom:-40vh
}
.spotlight-wrapper{
  opacity:.5;
  transition:opacity .4s ease-in
}
.nuxt-logo:hover~.spotlight-wrapper{
  opacity:.95
}
.gradient-border{
  position:relative;
  border-radius:.5rem;
  -webkit-backdrop-filter:blur(10px);
  backdrop-filter:blur(10px);
  width:100%
}
@media (prefers-color-scheme: light){
  .gradient-border
  {
    background-color:#ffffff4d
  }
  .gradient-border:before{
    background:linear-gradient(90deg,#e2e2e2 0%,#e2e2e2 25%,#00DC82 50%,#36E4DA 75%,#0047E1 100%)
  }
}
@media (prefers-color-scheme: dark){
  .gradient-border
  {
    background-color:#1414144d
  }
  .gradient-border:before{
    background:linear-gradient(90deg,#303030 0%,#303030 25%,#00DC82 50%,#36E4DA 75%,#0047E1 100%)
  }
}
.gradient-border:before{
  content:"";
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-radius:.5rem;
  padding:2px;
  width:100%;
  background-size:400% auto;
  background-position:0 0;
  opacity:.5;
  transition:background-position .3s ease-in-out,opacity .2s ease-in-out;
  -webkit-mask:linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
  mask:linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
  -webkit-mask-composite:xor;
  mask-composite:exclude
}
.gradient-border:hover:before{
  background-position:-50% 0;
  opacity:1
}
</style>